<template>
  <List el="xxsm" class="group-list">
    <List
      el="xxsm"
      class="group"
      v-for="item in groups.list"
      :key="item"
      :class="{ active: item.id === groups.active }"
    >
      <List el="xxsm" class="group-summary" @click="toggleGroup(item.id)">
        <Text style="font-size:20px;">{{ item.name }}</Text>
        <Text el="bold">{{ calcGroupDuration(item) }} hrs</Text>
      </List>
      <List el="xsm" v-if="item.id === groups.active">
        <hr el />
        <List el="column-between xxsm">
          <Text>{{ formatTime(item.startTime) }}</Text>
          <List el="column xxsm">
            <Icon name="add_circle" @click="addStartTime(item, 30)" />
            <Icon name="remove_circle" @click="subtractStartTime(item, 30)" />
          </List>
        </List>
        <Btn el="rnd" @click="$router.push('/group')">Confirm Start Time</Btn>
      </List>
    </List>
  </List>
</template>
<script>
import Icon from "@/components/btn/Icon.vue";
const dayjs = require("dayjs");
let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import {
  // Layout,
  // Header,
  Btn,
  List,
  // Accordion,
  // Input,
  Text,
  // Link,
  // Modal,
  // Alert,
} from "@/design-system";

export default {
  name: "Home",
  components: {
    Icon,
    // Layout,
    // Header,
    Btn,
    List,
    // Accordion,
    // Input,
    Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {
      groups: this.$store.state.groups,
    };
  },
  methods: {
    toggleGroup(id) {
      if (this.groups.active === id) {
        this.groups.active = "";
      } else {
        this.groups.active = id;
      }
    },
    calcGroupDuration(group) {
      let sum = 0;
      group.sections.forEach((item) => {
        sum += item.duration;
      });
      return Math.round((sum / 60) * 100) / 100;
    },
    addStartTime(item, amt) {
      let start = dayjs(item.startTime, "H:mm");
      item.startTime = start.add(amt, "minute").format("H:mm");
    },
    subtractStartTime(item, amt) {
      let start = dayjs(item.startTime, "H:mm");
      item.startTime = start.subtract(amt, "minute").format("H:mm");
    },
    formatTime(time) {
      return dayjs(time, "H:mm").format("h:mma");
    },
  },
  mounted() {
    console.log(this.$store.state.groups.list);
    // this.$store.commit("collapseSection", true);
  },
};
</script>
<style lang="scss" scoped>
.group-list {
  padding: 0 12px;
  max-width: 400px;
  margin: auto;
}
.group-summary {
  user-select: none;
}
.group {
  --bg-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 20px;
  padding: 20px;
  transition: 0.2s;
}
.group:hover
// .group.active 
 {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: white;
  cursor: pointer;
}
</style>
