<template>
  <component :is="tag" :el="mods"><slot></slot></component>
</template>

<script>
import { check } from "./verify";
const name = "text";
export default {
  props: ["el"],
  computed: {
    mods() {
      return this.el ? this.el : "p";
    },
    tag() {
      if (this.el === "bold") {
        return "p";
      } else {
        return this.mods.split(/[-\s]/).pop();
      }
    },
  },
  watch: {
    el(val) {
      check(name, val);
    },
  },
  mounted() {
    check(name, this.mods);
  },
};
</script>
