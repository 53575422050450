import { defineAsyncComponent } from "vue";
// components
import Layout from "./components/vue/Layout.vue";
import Header from "./components/vue/Header.vue";
import List from "./components/vue/List.vue";
import Btn from "./components/vue/Btn.vue";
import Accordion from "./components/vue/Accordion.vue";
import Text from "./components/vue/Text.vue";
import Input from "./components/vue/Input.vue";
import Link from "./components/vue/Link.vue";
import Alert from "./components/vue/Alert.vue";
// import Modal from "./components/vue/Modal.vue";
const Modal = defineAsyncComponent(() => import("./components/vue/Modal.vue"));
// utility functions
// import {} from "module";

export {
  Layout,
  Header,
  Btn,
  List,
  Accordion,
  Text,
  Input,
  Link,
  Modal,
  Alert,
};
