import { createStore } from "vuex";

export default createStore({
  state: {
    layout: {
      activeTransition: false,
    },
    screenLock: false,
    countdown: {
      timer: null,
      timeout: null,
      mins: 0,
      sec: 0,
      past: false,
    },
    sections: {
      prev: null,
      current: null,
      currentIndex: null,
      next: null,
    },
    groups: {
      active: 1,
      list: [
        {
          id: 1,
          name: "Connect Group",
          description: "",
          private: true,
          author: {
            uid: 23135646151,
            name: "David Mathews",
          },
          autoAdvance: true,
          sections: [
            {
              name: "Connect",
              duration: 10,
            },
            {
              name: "Celebrate",
              duration: 20,
            },
            {
              name: "Communicate",
              duration: 10,
            },
            {
              name: "Collaborate",
              duration: 20,
            },
            {
              name: "Care",
              duration: 10,
            },
            {
              name: "Contact",
              duration: 3,
            },
            {
              name: "Connect",
              duration: 30,
            },
          ],
          startTime: "18:00",
        },
      ],
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
