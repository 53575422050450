<template>
  <span class="material-icons">{{ name }}</span>
</template>
<script>
// import {
//   //   Layout,
//   // Header,
//   // Btn,
//   // List,
//   // Accordion,
//   // Input,
//   // Text,
//   // Link,
//   // Modal,
//   // Alert,
// } from "@/design-system";

export default {
  props: ["name"],
  components: {
    // Layout,
    // Header,
    // Btn,
    // List,
    // Accordion,
    // Input,
    // Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.material-icons {
  cursor: pointer;
  transition: 0.2s;
  color: hsla(0, 0%, 100%, 0.4);
  user-select: none;
  font-size: 35px;
}
.material-icons:hover {
  color: #488dcb;
}
</style>
