<template>
  <div class="page">
    <GroupList />
    <!-- <List el="xsm">
      <div class="google-btn">Sign In</div>
    </List> -->
  </div>
</template>

<script>
import GroupList from "@/components/GroupList.vue";
// import Icon from "@/components/btn/Icon.vue";
// import {
// Layout,
// Header,
// Btn,
// List,
// Accordion,
// Input,
// Text,
// Link,
// Modal,
// Alert,
// } from "@/design-system";

export default {
  name: "Home",
  components: {
    GroupList,
    // Icon,
    // Layout,
    // Header,
    // Btn,
    // List,
    // Accordion,
    // Input,
    // Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {
      groups: {
        list: [
          {
            name: "Connect Group",
            description: "",
            private: true,
            author: {
              uid: 23135646151,
              name: "David Mathews",
            },
            autoAdvance: true,
            sections: {
              connect: {
                duration: 10,
              },
              celebrate: {
                duration: 20,
              },
              communicate: {
                duration: 30,
              },
            },
            startTime: "7:30",
          },
          {
            name: "Conference",
            description: "",
            private: true,
            author: {
              uid: 23135646151,
              name: "David Mathews",
            },
            autoAdvance: true,
            sections: {
              connect: {
                duration: 10,
              },
              celebrate: {
                duration: 20,
              },
              communicate: {
                duration: 30,
              },
            },
            startTime: "7:30",
          },
        ],
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.google-btn {
  height: 50px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
  padding: 0 20px;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.87);
  font-weight: 500;
  text-align: center;
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 16px center;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}
.google-btn:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: white;
  cursor: pointer;
}
</style>
