<template>
  <List el="column xxsm" id="clock" @click="$router.push('/')">
    <span class="material-icons">schedule</span>
    <Text el="h3">{{ currentTime }}</Text>
  </List>
</template>
<script>
const dayjs = require("dayjs");
import {
  //   Layout,
  // Header,
  // Btn,
  List,
  // Accordion,
  // Input,
  Text,
  // Link,
  // Modal,
  // Alert,
} from "@/design-system";

export default {
  props: ["name"],
  components: {
    // Layout,
    // Header,
    // Btn,
    List,
    // Accordion,
    // Input,
    Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {
      currentTime: "00:00",
      timer: null,
    };
  },
  methods: {
    getTime() {
      this.currentTime = dayjs().format("h:mm");
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  mounted() {
    let x = this;
    x.getTime();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      x.getTime();
    }, 5000);
  },
};
</script>
<style lang="scss" scoped>
#clock {
  align-items: center;
}
.material-icons {
  cursor: pointer;
  transition: 0.2s;
  color: white;
}
</style>
