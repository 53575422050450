<template>
  <Layout
    el="hm fixed"
    :class="{
      layout: true,
      activeTransition: $store.state.layout.activeTransition,
    }"
  >
    <Header id="header">
      <List el="column-between xsm" id="header-content">
        <Clock />
        <Icon
          name="settings"
          @click="$router.push('/group/settings')"
          v-if="$route.path == '/group'"
        />
        <Icon
          name="west"
          @click="$router.go(-1)"
          v-if="$route.path == '/group/settings'"
        />
      </List>
    </Header>
    <main el="main">
      <router-view />
    </main>
  </Layout>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view /> -->
</template>
<script>
import Icon from "@/components/btn/Icon.vue";
import Clock from "@/components/Clock.vue";
import {
  Layout,
  Header,
  // Btn,
  List,
  // Accordion,
  // Input,
  // Text,
  // Link,
  // Modal,
  // Alert,
} from "@/design-system";

export default {
  name: "App",
  components: {
    Clock,
    Icon,
    Layout,
    Header,
    // Btn,
    List,
    // Accordion,
    // Input,
    // Text,
    // Link,
    // Modal,
    // Alert,
  },
  mounted() {
    let x = this;
    navigator.wakeLock.request("screen").then((lock) => {
      x.$store.state.screenLock = lock;
    });
    document.addEventListener("visibilitychange", async () => {
      if (
        x.$store.state.screenLock !== null &&
        document.visibilityState === "visible"
      ) {
        x.$store.state.screenLock = await navigator.wakeLock.request("screen");
      }
    });
  },
};
</script>

<style lang="scss">
@import "design-system/assets/scss/standard";
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }
body {
  padding: 0;
  margin: 0;
  background-color: #121212;
}
body * {
  user-select: none;
}
#header {
  padding: 12px;
}
#header-content {
  width: 100%;
  max-width: 376px;
  margin: auto;
  align-items: center;
  user-select: none;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.layout {
  transition: background-color 1s;
}
.activeTransition {
  background-color: #488dcb;
}
</style>
